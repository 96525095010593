<template>
  <div>
    <Survey :survey="survey" />
    <div @click="back">
      <back-button backLink="/" />
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton';
const VUE_APP_URL = process.env.VUE_APP_URL || '';
// import 'survey-core/modern.min.css';
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-vue-ui";

StylesManager.applyTheme("defaultV2");
const surveyJson = {
  "logoPosition": "right",
  "completedHtml": "<h3>Thank you for completing the survey!</h3><div style=\"margin: 32px 0;\"></div>",
  "pages": [
    {
      "name": "page1",
      "elements": [
        {
          "type": "comment",
          "name": "message",
          "title": "Let us know what you think!",
          "isRequired": true
        }
      ]
    }
  ],
  "triggers": [
    {
      "type": "runexpression"
    }
  ],
  "calculatedValues": [
    {
      "name": "var1"
    },
    {
      "name": "var2"
    }
  ]
};
export default {
  name: 'Feedback',
  data() {
    const survey = new Model(surveyJson);
    survey.focusFirstQuestionAutomatic = false;
    survey.onComplete.add(this.onSendMessage);
    return {
      survey,
    };
  },
  components: {
    BackButton,
    Survey,
  },
  methods: {
    onSendMessage(sender) {
      this.axios
        .post(VUE_APP_URL + `/api/v1/feedback`, sender.data)
        .then(() => {
          this.$vToastify.info({
            body: 'Feedback sent',
            title: ' ',
            duration: 1000,
            icon: ' ',
            type: 'info'
          });
        })
        .catch(error => {
          console.error(error);
          this.$vToastify.info({
            body: 'An error has occurred',
            title: ' ',
            duration: 1000,
            icon: ' ',
            type: 'error'
          });
        });
    },
    back() {
      this.$router.push({ name: 'Home' });
    }
  },
};
</script>

<style lang="scss" scoped>
  .sd-root-modern {
    background-color: transparent;
  }

  ::v-deep {
    .sd-page {
      margin-top: 10vh;
    }
    .sd-completedpage {
      margin-top: 20vh;
      margin-bottom: 19vh;
    }
    .sd-element.sd-question {
      box-shadow: 0px 1px 10px rgb(0 0 0 / 20%) !important;
    }
    .sd-title {
      font-family: Karla;
    }
    .sd-btn {
      font-family: Karla;
    }
    .sd-element__num {
      display: none;
    }
  }
</style>
